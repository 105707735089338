@import './../../../../scss/theme-bootstrap';

.product-brief__sizes-wrapper,
.product-full__options {
  ul.sku-list-sizes__list {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    li.sku-list-sizes__item {
      &[data-sku-list-order='0'] {
        order: 1;
      }
      &[data-sku-list-order='1'] {
        order: 2;
      }
      &[data-sku-list-order='2'] {
        order: 3;
      }
      &[data-sku-list-order='3'] {
        order: 4;
      }
    }
  }
  .sku-list-sizes {
    &__item {
      padding-#{$rdirection}: 0.5em;
      display: inline-block;
      font-size: 15px;
      max-width: 164px;
      height: 33px;
    }
    &__button {
      text-decoration: none;
      border: 1px solid $color-black;
      box-shadow: inset 0 0 0 2px $color-white;
      display: inline-block;
      font-size: 15px;
      height: 30px;
      line-height: 2;
      padding: 0 11px;
      white-space: nowrap;
      &.active {
        box-shadow: inset 0 0 0 2px $color-black;
      }
    }
    &__price {
      display: none;
    }
  }
}

.product-full__options {
  .sku-list-sizes {
    @include breakpoint($medium-up) {
      padding-inline-end: 60px;
    }
    .sku-list-sizes__list {
      display: grid;
      gap: 0.5em;
      grid-template-columns: repeat(3, 1fr);
    }
    .sku-list-sizes {
      &__item {
        padding-inline-end: 0;
        height: fit-content;
        max-width: unset;
      }
      &__button {
        border-color: $color-gray;
        box-shadow: unset;
        line-height: 1.2;
        height: fit-content;
        width: 100%;
        text-align: start;
        padding: 12px;
        @include breakpoint($medium-up) {
          padding-block: 4px;
        }
        &.active {
          box-shadow: unset;
          border-color: $color-black;
        }
      }
      &__price {
        display: block;
        color: $text-color-primary;
      }
    }
  }
}