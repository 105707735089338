///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout

$max-width: 1280px !default;
$max-width-large: 1440px !default;
$min-width: 320px !default;

$banner-height: 30px !default;
$banner-height--mobile: 30px !default;
$header-main-height: 65px !default;
$header-height: $banner-height + $header-main-height !default; //75
$header-height--mobile: 40px;
$header-height-large--mobile: 50px;
$header-height-overlay--mobile: 55px;
$header-height-overlay--pc: 65px;
$header-height-search--mobile: 96px;
$countdown-banner-height-mobile: 75px !default;
$countdown-banner-height-desktop: 45px !default;

// Typography
//$base-font-size: 13px !default;
$base-font-size: 14px;
$base-line-height: 1.28 !default;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-black: #000000 !default;
$color-lighted-gray: #cccccc !default;
$color-light-black: #303030 !default;
$color-light-white: #e3e3e3 !default;
$color-medium-gray: #e3e3e3 !default;
$color-fade-white: #fff6 !default;
$color-fade-gray: #787172 !default;
$color-white: #ffffff !default;
$color-fadeout-gray: #aea9a9 !default;
$color-gray: #959595 !default;
$color-grey: $color-gray !default;
$color-pink: #ff4661 !default;
$color-red: #d74027 !default;
$color-orange-red: #ff4500 !default;
$color-red-error: #d00 !default;
$color-light-pink: #ffcccc !default;
$color-dark-pink: #cc4661 !default;
$color-light-brown: #f7f4ef !default;
$color-lightest-gray: #f2f2f2 !default;
$color-lighter-gray: #f6f6f6 !default;
$color-thick-gray: #e6e6e6 !default;
$color-bg-gray: #f5f5f5 !default;
$color-light-gray: #ebebeb !default;
$color-dark-gray: #666666 !default;
$color-background-gray: #f9f9f9 !default;
$color-banner-gray: #959595;
$color-lighter-black: #1b1b20 !default;
$color-link-gray: #999 !default;
$color-text: $color-black !default;
$color-link: $color-black !default;
$color-link-hover: $color-gray !default;
$black: $color-black !default;
$color-light-saffron: #ffd5ab !default;
$white: $color-white !default;

$color-border: $color-light-gray;
$color-bg: $color-lighter-gray;
$color-reviews: $color-gray;
$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;
// common variables
$border: $black solid 1px !default;

// frontend validation variables
$border-color-normal: $color-light-gray;
$border-color-error: $color-red;
$color-valid: green;
$color-error: $color-red;
$icon-background: white;
$inline-error-background: white;

// transparent colors (primarily for overlays)
$color-transparent--white: rgba(255, 255, 255, 0.7) !default;
$color-transparent--black: rgba(0, 0, 0, 0.5) !default;
$color-transparent--light: $color-transparent--white;
$color-transparent--dark: $color-transparent--black;
$color-transparent--grey: rgba(100, 100, 100, 0.5);
$color-transparent--lightblack: rgba(0, 0, 0, 0.75);
$color-overlay--black: rgba(0, 0, 0, 0.3) !default;
/* Password Strengthening */
$color-green: #05ba59 !default;
$color-light-orange: #fef2f2 !default;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;

// ADA colors
$color-light-blue: #5f93ec;

// Default transition settings
$transition-duration: 0.3s;
$transition-effect: ease-in-out;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// Search
// @todo cleanup
$mobile_search_header_height: 60px;
$pc-search-width: 551px;

// Left to right support
$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;

// avenir
$font--avenir-light: 'avenir-light', sans-serif !default;
$font--avenir-book: 'avenir-book', sans-serif !default;
$font--avenir-roman: 'avenir-roman', sans-serif !default;
$font--avenir-medium: 'avenir-medium', sans-serif !default;
$font--avenir-heavy: 'avenir-heavy', sans-serif !default;
$font--avenir-black: 'avenir-black', sans-serif !default;
$font--loyalty-bumber: 'bb-loyalty-number', sans-serif !default;
$font--avenir-arial: 'avenir-roman', arial !default;

$base-font-family: $font--avenir-roman !default;

// Expose Rating stars by default on MPP page
$show-reviews-on-hover: false !default;

$color--white: hsl(0, 0%, 100%) !default;
$color--white--opacity-1: rgba(255, 255, 255, 0.1);
$color--white--opacity-3: rgba(255, 255, 255, 0.3);
$color--white--opacity-4: rgba(255, 255, 255, 0.4);
$color--white--opacity-8: rgba(255, 255, 255, 0.8);
$color--white--opacity-9: rgba(255, 255, 255, 0.9);
$color--transparent: hsla(0, 0%, 100%, 0) !default;
$color--gray--lighter-white: hsl(0, 0%, 98%) !default;
$color--gray--white: hsl(0, 0%, 94%) !default;
$color--gray--lightest: hsl(0, 0%, 85.9%) !default;
$color--gray--lighter: hsl(0, 0%, 78%) !default;
$color--gray--light: hsl(0, 0%, 60%) !default;
$color--gray: hsl(228, 2.3%, 41.8%) !default;
$color--gray--dark: hsl(0, 0%, 30%) !default;
$color--gray--darker: hsl(0, 0%, 20%) !default;
$color--gray--darkest: hsl(0, 0%, 16%) !default;
$color--gray--medium: hsl(0, 0%, 64%) !default;
$color--gray--black: hsl(0, 0%, 10%) !default;
$color--gray--message: hsl(0, 0%, 60.8%) !default;
$color--gray--dusty: #999;
$color--black: hsl(0, 0%, 0%) !default;

$percentage-unit: unquote('%');

$text-color-primary: #212121;
